.centro{
    margin-left: auto;
    margin-right: auto;
}

.txt-g{
    text-overflow: ellipsis;
    overflow: hidden;
}

.cursor-pointer{
    cursor: pointer;
}

.tavatar{
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.list-group-item-action:hover{
    background: #f1f1f1;
}

.bg-search{
    background: #3c3c3c;
    color: white;
}

.bg-gray{
    background: #4e4e4e;
    color: white;
}

.bg-search:focus{
    background-color: #3c3c3c;
    color: white;
}

.bg-search:active{
    background-color: #3c3c3c;
    color: white;
}

.bg-search::placeholder{
    color: #d9d9d9;
}

.overflow{
    overflow-y: scroll;
    height: 100%;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.contenedor::-webkit-scrollbar {
    -webkit-appearance: none;
}

.contenedor::-webkit-scrollbar:vertical {
    width:10px;
}

.contenedor::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
} 

.contenedor::-webkit-scrollbar:horizontal {
    height: 10px;
}

.contenedor::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.contenedor::-webkit-scrollbar-track {
    border-radius: 10px;  
}

.contenedor {
    /*Estilos estándar experimentales (Principalmente Firefox)*/
    scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
}

/**/
.z-3{
    z-index: 3 !important;
}

.pe-32{
    padding-right: 32px;
}

.shadow-none {
    outline: 0;
    box-shadow: none;
}

.btn-f{
    bottom: 5%;
    right: 5%;
    width: 50px;
    height: 50px;
}
